var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"m-masthead bg-primary",attrs:{"role":"banner","data-masthead-london":""}},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"p-top relative"},[_c('div',{staticClass:"g align-apart align-v-center"},[_c('div',{staticClass:"g__col"},[_c('masthead-brand-london')],1)]),_c('div',{staticClass:"mg-top"},[_c('masthead-desktop-primary-nav')],1),_c('nav',{staticClass:"m-masthead__nav"},[_c('ul',{staticClass:"m-masthead__nav-list list--inline"},[_vm._l((_vm.state.navData.AuxillaryItems),function(auxItem,idx){return _c('li',{key:auxItem.Url},[_c('a',{staticClass:"m-masthead__functional-nav-item",class:{
                'm-masthead__nav-item--has-divider': _vm.hasDivider(idx),
              },attrs:{"href":auxItem.Url}},[_c('span',{class:{
                  'm-masthead__nav-item__divider': _vm.hasDivider(idx),
                },domProps:{"innerHTML":_vm._s(auxItem.Title)}})])])}),_c('li',[_c('button',{ref:"searchButton",staticClass:"m-masthead__nav-item",attrs:{"aria-expanded":_vm.state.isSearchExpanded ? 'true' : 'false',"aria-controls":_vm.id('search'),"type":"button"},on:{"click":_vm.toggleSearch,"focus":function($event){return _vm.bindTabKey({
                  condition: 'isSearchExpanded',
                  from: 'searchButton',
                })},"blur":_vm.unbindTabKey}},[_c('span',{staticClass:"sr-only"},[_vm._v(" "+_vm._s(_vm.state.isSearchExpanded ? "Hide" : "Expand")+" ")]),_c('span',{staticClass:"mg-right--half"},[_vm._v("Search")]),_c('icon',{attrs:{"name":_vm.state.isSearchExpanded ? "close" : "search","title":"Search"}})],1)])],2)])])]),_c('portal',{attrs:{"selector":"#mobile-masthead-nav-search","aria-live":"polite"}},[_c('div',{staticClass:"shu-design-system-v1-0"},[(_vm.state.isSearchExpanded)?_c('masthead-search',{attrs:{"id":_vm.id('search')},on:{"hide":_vm.handleSearchClose}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }