<template>
  <header class="m-masthead bg-primary" role="banner" :style="{ ...conditionalFixedStyle }">
    <div class="wrapper">
      <masthead-mobile-menu-bar-london />
    </div>
    <portal selector="#mobile-masthead-nav-search" aria-live="polite">
      <div class="shu-design-system-v1-0">
        <masthead-mobile-nav-london v-if="state.isMenuExpanded" :id="id('nav')" @hide="toggleMenu" />
        <masthead-search
          v-if="state.isSearchExpanded"
          :id="id('search')"
          @hide="handleSearchClose"
        />
      </div>
    </portal>
  </header>
</template>
<script>
import { Portal } from "@linusborg/vue-simple-portal";

import { bus } from "@/components/molecules/masthead/bus";

import MastheadBase from "@/components/molecules/masthead/vue-components/base/MastheadBase";

import MastheadSearch from "@/components/molecules/masthead/vue-components/shared/MastheadSearch";
import MastheadMobileNavLondon from "@/components/london/masthead-london/vue-components/mobile/MastheadMobileNavLondon";
import MastheadMobileMenuBarLondon from "@/components/london/masthead-london/vue-components/mobile/MastheadMobileMenuBarLondon";

export default {
  name: "MastheadMobileLondon",
  components: {
    MastheadMobileMenuBarLondon,
    MastheadMobileNavLondon,
    MastheadSearch,
    Portal,
  },
  extends: MastheadBase,
  computed: {
    conditionalFixedStyle() {
      return {
        position: this.state.isMenuExpanded ? "fixed" : "relative",
        inset: "0 0 auto 0",
      };
    },
  },
  beforeDestroy() {
    this.toggleMenu(false);
    this.toggleSearch(false);
  },
  methods: {
    handleSearchClose() {
      this.toggleSearch(false);
      bus.$emit("focussearchbutton");
    },
  },
};
</script>
